<template>
  <div
    class="hover-dropdown-fade w-auto px-0 ml-6 position-relative hp-cursor-pointer"
  >
    <div
      class="hp-cursor-pointer border hp-border-color-dark-80"
      style="border-radius: 15px"
    >
      <div
        class="rounded-lg overflow-hidden m-4 d-flex"
      >
        <b-avatar
          variant="none"
          :src="require('@/assets/img/memoji/user-avatar-4.png')"
          size="32px"
          class="rounded-0 hp-bg-info-4"
        />
      </div>
    </div>

    <div
      class="hp-header-profile-menu dropdown-fade position-absolute pt-18"
      style="top: 100%; width: 260px"
    >
      <div class="rounded hp-bg-black-0 hp-bg-dark-100 py-24 px-18">
        <span
          class="d-block h5 font-weight-medium hp-text-color-black-100 hp-text-color-dark-0 mb-16"
        >
          您好，管理员
        </span>

        <b-link
          to="/pages/profile"
          class="hp-p1-body font-weight-medium hp-hover-text-color-primary-2"
        >
          查看资料
        </b-link>

        <div class="divider mt-18 mb-16"></div>

        <b-row>
          <b-col cols="12">
            <b-link
              class="hp-p1-body font-weight-medium hp-hover-text-color-primary-2"
              to="/pages/profile/password-change"
            >
              修改资料
            </b-link>
          </b-col>

          <b-col cols="12" class="mt-24">
            <b-link
              class="hp-p1-body font-weight-medium hp-hover-text-color-primary-2"
              @click="tuichu"
            >
              退出登陆
            </b-link>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BAvatar, BLink } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BLink,
  },
  methods:{
    async tuichu(){
      var ret = await this.$axios.post('/user_api/loginout');
      if(ret){
        if(ret.code == 1){
          window.localStorage.removeItem('token');
          this.$router.replace('/login');
        }
      }
    }
  }
};
</script>
