import http from '../utils/http'

async function getMeu() {
  var ret = await http.post('/user_api/meuList');
  if (ret) {
    if (ret.code == 1) {
      return ret.list;
    }
  }
}


export default getMeu;